import React from "react";
import ReactPlayer from "react-player";
import RichText from "../RichText/RichText";

import {
  videoWrapper,
  creditsWrapper,
  creditStyles,
} from "./VideoPlayer.module.scss";

const VideoPlayer = ({ url, title, credits, className }) => {
  return (
    <div className={`${videoWrapper} ${className}`}>
      <ReactPlayer
        url={url}
        width="100%"
      />
      <div className={creditsWrapper}>
        <h3>{title}</h3>
        <RichText richText={credits} className={creditStyles} />
      </div>
    </div>
  );
};

export default VideoPlayer;
