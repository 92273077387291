import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout/Layout";
import RichText from "../components/RichText/RichText";
import PageDetails from "../components/PageDetails/PageDetails";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

import {
  about,
  pageContent,
  img,
  articles,
  cvLink,
  videosSection,
  videoPlayer
} from "./about.module.scss";

const About = ({ data }) => {
  const { contentfulAboutPage } = data;
  const { pageTitle, headshot, bio, articlesList, cv, videos } =
    contentfulAboutPage;

  return (
    <Layout>
      <PageDetails heading={pageTitle} />
      <section className={`grid-wrapper ${about}`}>
        <div className={pageContent}>
          <GatsbyImage
            image={headshot.gatsbyImageData}
            alt={headshot.description}
            className={img}
          />
          <RichText richText={bio} />
        </div>
        <a
          href={cv.url}
          className={`button ${cvLink}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          See My CV
        </a>
      </section>
      <section className={`grid-wrapper ${videosSection}`}>
        {videos.map((video) => (
          <VideoPlayer
            key={video.id}
            url={video.videoLink}
            title={video.title}
            credits={video.credits}
            className={videoPlayer}
          />
        ))}
      </section>
      <section className={`grid-wrapper ${articles}`}>
        <RichText richText={articlesList} />
      </section>
    </Layout>
  );
};

export default About;

export const queryAbout = graphql`
  query about {
    contentfulAboutPage {
      pageTitle
      headshot {
        gatsbyImageData(quality: 100)
        description
      }
      bio {
        raw
      }
      articlesList {
        raw
      }
      cv {
        url
      }
      videos {
        id
        title
        credits {
          raw
        }
        videoLink
      }
    }
  }
`;

export { Head } from "../components/Head/Head";
